import { Tab } from "../interfaces";
import { ReactComponent as ChevronRight } from "../assets/chevron-right.svg";

const InfoTabs: Tab[] = [
  // {
  //   label: "Patient Notes",
  //   slug: "patientNotes",
  // },
  {
    label: "Notes",
    slug: "notes",

    cbd: {
      __html: ``,
    },

    thc: {
      __html: ``,
    },
  },

  {
    label: "Medication",
    slug: "medication",
    initial: `It was lovely to speak with you today to discuss your health
      journey and how medicinal cannabis may help. I have included a
      link for you to purchase your medication below. After completing
      this step, we will process your order and courier your
      medication to you (don't forget to include the correct delivery
      address at the checkout).`,

    followUp: {
      __html: `Thank you for attending your consultation.  <br/><br/>

        Please order your prescribed medication via the link(s) provided. Your order will be processed in 2-3 working days, and then shipped securely and confidentially to your door via courier (don't forget to include the correct delivery address at the checkout).<br/><br/>

        Alternatively, we can send your script to your preferred pharmacy. To do so, please email us here with your name, DOB, pharmacy details (including their email address). We recommend checking with the pharmacy first to ensure they have your medication in stock.<br/><br/>

        Please also note that we cannot release your product/s or script unless your consultation invoice has been paid.<br/><br/>

        Please email us here: <a style='word-wrap: break-word; color:blue; text-decoration: underline;' href="email:support@cannabisclinic.co.nz"> support@cannabisclinic.co.nz</a>`,
    },
  },
  {
    label: "Directions",
    slug: "directions",
  },
  {
    label: "Dose",
    slug: "dose",
    all: `Your doctor's recommended dosing instructions will be printed on the medication for you. <br/><br/>

          There is no scientifically defined dose of medicinal cannabis for any specific medical condition. <br/><br/>

          Dosing remains highly individualised and relies greatly on titration - it could take time for us to adjust the product and dosage to obtain the most health benefits. <br/><br/>

          We will begin with a 4 week trial period and regularly assess against our agreed upon objectives.`,
  },
  {
    label: "Precautions",
    slug: "precautions",
    cbd: {
      __html: `Avoid using alcohol and stay well hydrated. Keep any cannabis products out of the reach of children and locked in a safe place. <br/><br/>
  
      This product should not be shared with anyone else. If you have not consumed cannabis before, it would be prudent to have someone with you the first time you use it.  <br/><br/>
      
      Stop using this product right away and consult your doctor if you begin to experience any unexpected effects or side effects.   <br/><br/>`,
    },

    thc: {
      __html: `THC can cause impairment – it is important to avoid driving, working at heights or operating heavy machinery for at least 10 hours after taking it.  <br/><br/>
  
      Using alcohol alongside THC significantly increases risk of impairment and adverse effects and is therefore not recommended.    For those with safety sensitive roles, we recommend talking with your employer prior to commencing treatment as THC use will result in a positive drug test and its use may raise safety concerns in certain work environments.  <br/><br/>
      
      Stay well hydrated. Keep any cannabis products out of the reach of children and locked in a safe place.  <br/><br/>
      
      This product should not be shared with anyone else. If you have not consumed cannabis before, it would be prudent to have someone with you the first time you use it.   <br/><br/>
      
      Stop using this product right away and consult your doctor if you begin to experience any unexpected effects or side effects.  <br/><br/>`,
    },
  },
  // {
  //   label: "Adjustments",
  //   slug: "adjustments",
  //   all: `There is no scientifically defined dose of medicinal cannabis for
  //     any specific medical condition. Dosing remains highly individualised
  //     and relies greatly on titration - it could take time for us to
  //     adjust the product and dosage to obtain the most health benefits. We
  //     will begin with a 4 week trial period and regularly assess against
  //     our agreed upon objectives.`,
  // },
  // {
  //   label: "Follow Up",
  //   slug: "followUp",
  //   // cbd: {
  //   //   __html: `You must have a follow-up so we can monitor your progress and make sure you are on the best product and dosage.  <br/><br/>

  //   //   Our standard follow-up timeframe is 4 weeks, however, your doctor will let you know if they need to see you earlier or later than this. <br/><br/>

  //   //   Please book your follow-up consultation here:  <a style='color:blue; text-decoration: underline;' href="https://cannabisclinic.co.nz/appointment-request/">https://cannabisclinic.co.nz/appointment-request/</a>
  //   //   `,
  //   // },

  //   // thc: {
  //   //   __html: `As this is a THC product, you must have a follow-up so we can monitor your progress and make sure you are on the best product and dosage.  <br/><br/>

  //   //   Our standard follow-up timeframe is 4 weeks, however, your doctor will let you know if they need to see you earlier or later than this. <br/><br/>

  //   //   Please book your follow-up consultation here:  <a style='color:blue; text-decoration: underline;' href="https://cannabisclinic.co.nz/appointment-request/">https://cannabisclinic.co.nz/appointment-request/</a>`,
  //   // },
  // },
  {
    label: "Journaling",
    slug: "journaling",
    all: {
      __html: `We encourage you to use <a style='word-wrap: break-word; color:blue; text-decoration: underline;' href='https://cannabisclinic.co.nz/wp-content/uploads/2022/09/brochure-3-print-pages-1-2.pdf'>this journal</a> to write down your experiences.  <br/><br/>

          Please bring this to your next consultation so we can see how things are progressing. It will also help us determine your next best steps.`,
    },
  },
  {
    label: "Support",
    slug: "support",
    all: {
      __html: `In between your Cannabis Clinic consultations, we are here to support you. <br/><br/>
  
          Please <a style='word-wrap: break-word; overflow-wrap: anywhere; color:blue; text-decoration: underline;' href='mailto:clinical@cannabisclinic.co.nz'> email our friendly clinical team</a> or phone us on 09 390-0059 if you need any on-going advice and help before our next consultation. <br/><br/>
          
          Thank you for choosing the Cannabis Clinic as the destination for your medicinal cannabis needs. <br/><br/>
          `,
    },
  },
  {
    label: "Follow Up",
    slug: "follow-up",
    all: {
      __html: ``,
    },
    content: (type: string | undefined) => (
      <>
        {/* <div className="flex justify-center items-center w-full h-full">
        </div> */}
        {(() => {
          if (type?.includes("cbd")) {
            return (
              <>
                You must have a follow-up so we can monitor your progress and
                make sure you are on the best product and dosage.
                <br />
                <br />
                You will need a follow up within your first 3 months - then it's
                just one every 3-6 months depending on your doctor's advice.
              </>
            );
          } else if (type?.includes("thc")) {
            return (
              <>
                As this is a THC product, you must have a follow-up so we can
                monitor your progress and make sure you are on the best product
                and dosage.
                <br />
                <br />
                You will need a follow up within the first month - then it's
                just one every 3 months.
              </>
            );
          } else {
            return <></>;
          }
        })()}
        <br />
        <br />
        Please book your follow-up consultation here:
        <button
          className="flex items-center mt-6 px-4 py-3 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl"
          onClick={() =>
            window.open("https://cannabisclinic.co.nz/consultation-booking/")
          }
        >
          Follow up
          <ChevronRight className="ml-2" />
        </button>
        {/* <a
          className="word-wrap: break-word; overflow-wrap: anywhere; color:blue; text-decoration: underline;"
          href="https://cannabisclinic.co.nz/consultation-booking/"
        >
          https://cannabisclinic.co.nz/consultation-booking/
        </a> */}
        <br />
        <br />
      </>
    ),
  },
];

export default InfoTabs;
