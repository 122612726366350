import axios from "axios";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select, { SingleValue } from "react-select";
import ellipse from "../assets/ellipse.svg";
import { SelectButton } from "../components/SelectButton";
import { TreatmentPlanContext } from "../contexts/TreatmentPlanContext";
import { Product, ProductCategory, ProductOption } from "../interfaces";

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    flex: 1,
    boxShadow: "none",
    width: "100%",
    fontSize: 12,
    height: 28,
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    boxShadow: "none",
    border: 0,
    fontSize: 12,
    fontColor: "#99999A",
    fontWeight: 200,
    minHeight: 20,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 22,
    padding: "0 6px",
  }),
  input: (provided: any) => ({
    ...provided,
    padding: 0,
    display: "flex",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any) => ({
    padding: 0,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 3,
  }),
};

// This is used so that we can change styling based on the strength of the product
export const resolveProductStrength = (product: Product | undefined) => {
  if (!product) return {};

  if (
    product.metaData.product_type === "thc" &&
    product.metaData.total_thc_percentage
  ) {
    let strengthRating;
    if (product.metaData.total_thc_percentage < 10) {
      strengthRating = "Low";
    } else if (product.metaData.total_thc_percentage <= 19) {
      strengthRating = "Medium";
    } else if (product.metaData.total_thc_percentage >= 20) {
      strengthRating = "High";
    }

    return strengthRating;
  }
};

export const CreateTreatmentPlan = () => {
  const navigate = useNavigate();

  const { treatmentPlan, updateTreatmentPlanField } =
    useContext(TreatmentPlanContext);

  const [parentCategory, setParentCategory] = useState<number>(81);
  const [categories, setCategories] = useState<ProductCategory[]>();
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>();
  const [products, setProducts] = useState<Product[]>([]);

  // Tracks the state of the product
  const isProductSelected = (product: Product) => {
    const foundProduct = treatmentPlan?.treatmentProducts?.find(
      (treatmentProduct) =>
        treatmentProduct.id === product.id ||
        treatmentProduct.product?.wcParentId === product.id
    );

    if (!foundProduct) {
      return null;
    } else {
      return foundProduct;
    }
  };

  // Used so that when a product is unselected it clears the notes,
  // which solves the issue of needing to persist the note state while unselected
  const getNotesValue = (product: Product) => {
    const foundProduct = isProductSelected(product);

    if (!foundProduct) {
      return "";
    } else {
      return foundProduct.notes;
    }
  };

  // Used to map through and update the specific products notes value
  const updateProductNotes = (product: Product, notes: string) => {
    const productVariation = getSelectedVariation(product);

    const treatmentProducts = treatmentPlan.treatmentProducts?.map((p) => {
      if (p.id === product.id || p.id === productVariation?.value.id) {
        return { ...p, notes };
      }

      return p;
    });
    updateTreatmentPlanField("treatmentProducts", treatmentProducts);
  };

  // Adds a product to our treatment products array
  const addProduct = (product: Product) => {
    const treatmentProducts = treatmentPlan.treatmentProducts;

    const productVariation = treatmentProducts?.find(
      (p) =>
        p.product?.wcParentId === product.wcParentId &&
        product.wcParentId !== null
    );

    // If the product already has a variation added to our treatment plan, then remove the existing variation
    // and add the new variation
    if (productVariation !== undefined) {
      const index = treatmentProducts?.indexOf(productVariation);
      if (typeof index === "number" && treatmentProducts) {
        // Remove other product variation
        const notes = treatmentProducts[index].notes;
        treatmentProducts?.splice(index, 1);

        // Add new product variation
        treatmentProducts?.push({
          id: product.id,
          quantity: 1,
          product,
          notes: notes,
        });
        updateTreatmentPlanField("treatmentProducts", treatmentProducts);
      }
    } else {
      treatmentProducts?.push({ id: product.id, quantity: 1, product });
      updateTreatmentPlanField("treatmentProducts", treatmentProducts);
    }
  };

  // Removes product or its variation product from products array
  const removeProduct = (product: Product) => {
    const treatmentPlanProducts = treatmentPlan.treatmentProducts;

    let treatmentProducts = treatmentPlanProducts?.filter(
      (addedProduct) => addedProduct.id !== product.id
    );

    // If the product has variations then remove the variation
    if (product.variations) {
      for (const productVariationId of product.variations) {
        treatmentProducts = treatmentProducts?.filter(
          (addedProduct) => addedProduct.id !== productVariationId
        );
      }
    }

    updateTreatmentPlanField("treatmentProducts", treatmentProducts);
  };

  // Returns a product option if the product variation, this is used as the value for our
  // selected drop down menu so we visually show the product variation that is selected
  const getSelectedVariation = (product: Product) => {
    let foundVariation;
    let variationOption: ProductOption;

    if (product.variations) {
      for (const productVariationId of product.variations) {
        let result = treatmentPlan?.treatmentProducts?.find(
          (product) => product.id === productVariationId
        );
        if (result) {
          foundVariation = result;
        }
      }
    }

    if (foundVariation?.product?.attributes[0].option) {
      variationOption = {
        label: foundVariation?.product?.attributes[0].option,
        value: foundVariation?.product,
      };

      return variationOption;
    }
  };

  const listProducts = async () => {
    try {
      const products = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}product`
      );

      const sortedProducts = products.data.sort((a: Product, b: Product) => {
        if (
          a.metaData &&
          a.metaData.product_priority &&
          b.metaData &&
          b.metaData.product_priority
        ) {
          return +a.metaData.product_priority - +b.metaData.product_priority;
        } else {
          return -1
        }
      });
      setProducts(sortedProducts);
    } catch (err) {
      console.log(err);
    }
  };

  const listCategories = async () => {
    try {
      const categories = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}category`
      );
      setCategories(categories.data);
    } catch (err) {
      console.log(err);
    }
  };

  const selectFirstChildCategory = async (parentCategory: number) => {
    if (categories) {
      for (const category of categories) {
        if (
          category.parent === parentCategory ||
          category.id === parentCategory
        ) {
          setSelectedCategory(category);
          return;
        }
      }
    }
  };

  const resolveStockStatus = (stockStatus: string) => {
    if (stockStatus === "instock") {
      return <span className="text-green-500">In Stock</span>;
    } else if (stockStatus === "onbackorder") {
      return <span className="text-orange-300">On Backorder</span>;
    } else if (stockStatus === "outofstock") {
      return <span className="text-red-400">Out Of Stock</span>;
    }
  };

  const submit = () => {
    if (treatmentPlan?.treatmentProducts?.length === 0) return;
    navigate("/add-treatment-plan");
  };

  useEffect(() => {
    selectFirstChildCategory(parentCategory);
  }, [parentCategory, categories]);

  useEffect(() => {
    listProducts();
    listCategories();
  }, []);

  return (
    <div className="flex flex-col overflow-x-hidden">
      <div className="mb-8 text-2xl md:text-4xl font-[1000]">
        Select a Treatment Plan
      </div>
      <div className="flex flex-row gap-6 mr-auto text-sm md:text-md font-semibold">
        <button
          onClick={() => setParentCategory(81)}
          className={classNames(
            "text-black flex flex-col items-center text-lg",
            {
              "text-cc-blue-500": parentCategory === 81,
            }
          )}
        >
          THC
        </button>

        <button
          onClick={() => setParentCategory(70)}
          className={classNames(
            "text-black flex flex-col items-center text-lg",
            {
              "text-cc-blue-500": parentCategory === 70,
            }
          )}
        >
          CBD
        </button>

        <button
          onClick={() => setParentCategory(42)}
          className={classNames(
            "text-black flex flex-col items-center text-lg",
            {
              "text-cc-blue-500": parentCategory === 42,
            }
          )}
        >
          Accessories
        </button>
      </div>
      <div className="flex flex-row gap-6 mr-auto text-sm md:text-md font-semibold mt-5">
        {categories?.map((category, i) => {
          if (category.parent === parentCategory) {
            return (
              <button
                key={i}
                onClick={() => setSelectedCategory(category)}
                className={classNames("text-black flex flex-col items-center", {
                  "text-cc-blue-500": selectedCategory === category,
                })}
              >
                {category.name}
                {selectedCategory === category && (
                  <img src={ellipse} alt="ellipse" className="mt-1" />
                )}
              </button>
            );
          } else if (category.id === 42 && parentCategory === 42) {
            return (
              <button
                key={i}
                onClick={() => setSelectedCategory(category)}
                className={classNames("text-black flex flex-col items-center", {
                  "text-cc-blue-500": selectedCategory === category,
                })}
              >
                {category.name}
                {selectedCategory === category && (
                  <img src={ellipse} alt="ellipse" className="mt-1" />
                )}
              </button>
            );
          }
        })}
      </div>

      <div className="mr-auto mt-9 overflow-y-auto pr-5 max-w-full">
        <div className="grid grid-cols-4 gap-5">
          {products.map((product, i) => {
            let isProductCategorySelected = false;
            for (const category of product.categories) {
              if (category.id === selectedCategory?.id) {
                isProductCategorySelected = true;
              }
            }
            if (isProductCategorySelected && !product.wcParentId) {
              let selected = isProductSelected(product);
              let productStrength = resolveProductStrength(product);
              let productOptions: ProductOption[] = [];

              return (
                <div
                  key={i}
                  className="flex flex-col text-sm sm:text-md max-w-[230px]"
                >
                  <div
                    className={classNames(
                      "flex flex-col py-4 items-center border-[1px] border-cc-gray-500 rounded-xl",
                      {
                        "border-cc-high-strength": productStrength === "High",
                        "border-cc-medium-strength":
                          productStrength === "Medium",
                        "border-cc-low-strength": productStrength === "Low",
                      }
                    )}
                  >
                    <div className="w-full px-7 flex items-center flex-col">
                      <img
                        src={product.imageSrc}
                        alt="ProductImage"
                        className="w-full ml-auto mr-auto"
                      />
                      <div className="flex flex-col items-center font-light text-xs sm:text-md min-h-[170px]">
                        <div className="font-bold min-h-[60px]">
                          {product.name}
                        </div>

                        <div className="flex flex-col items-center text-center">
                          <div className="text-cc-gray-100 text-xs mb-2 h-3">
                            {product.metaData.total_thc_percentage && (
                              <span>
                                THC: {product.metaData.total_thc_percentage}%{" "}
                              </span>
                            )}

                            {product.metaData.total_cbd_percentage && (
                              <span>
                                CBD: {product.metaData.total_cbd_percentage}%
                              </span>
                            )}
                          </div>
                          {productStrength && (
                            <div
                              className={classNames(
                                "my-2 font-bold text-orange-300",
                                {
                                  "text-cc-high-strength":
                                    productStrength === "High",
                                  "text-cc-medium-strength":
                                    productStrength === "Medium",
                                  "text-cc-low-strength":
                                    productStrength === "Low",
                                }
                              )}
                            >
                              {productStrength + " THC"}
                            </div>
                          )}

                          <div className="my-2">
                            ${product.metaData.unit_price}
                          </div>

                          {product.metaData.price_per_x && (
                            <div className="w-full text-center">
                              <div className="border-[0.5px] w-full border-cc-gray-500" />
                              <div className="my-2">
                                {product.metaData.price_per_x}
                              </div>
                            </div>
                          )}

                          {product.metaData.line_2 && (
                            <div className="w-full">
                              <div className="border-[0.5px] w-full border-cc-gray-500" />
                              <div className="my-2">
                                {product.metaData.line_2}
                              </div>
                            </div>
                          )}

                          {product.metaData.line_3 && (
                            <div className="w-full">
                              <div className="border-[0.5px] w-full border-cc-gray-500" />
                              <div className="my-2">
                                {product.metaData.line_3}
                              </div>
                            </div>
                          )}

                          {product.metaData.line_4 && (
                            <div className="w-full">
                              <div className="border-[0.5px] w-full border-cc-gray-500" />
                              <div className="my-2">
                                {product.metaData.line_4}
                              </div>
                            </div>
                          )}

                          <div className="w-full">
                            <div className="border-[0.5px] w-full border-cc-gray-500" />
                            <div className="my-2">
                              {resolveStockStatus(product.stockStatus)}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* If the product has variations, add each variation to our select drop down options */}
                      {product.variations?.length ? (
                        <div className="flex flex-col items-center">
                          <div className="border-[1px] rounded-full px-3 my-2">
                            {product.variations?.map((variationId) => {
                              const productVariation = products.find(
                                (p) => p.id === variationId
                              );
                              if (
                                productVariation &&
                                productVariation.attributes[0].option
                              ) {
                                productOptions.push({
                                  label: productVariation.attributes[0].option,
                                  value: productVariation,
                                });
                                return true;
                              }
                            })}
                            <Select
                              styles={customStyles}
                              options={productOptions}
                              onChange={(
                                productVariation: SingleValue<ProductOption>
                              ) => {
                                if (productVariation?.value)
                                  addProduct(productVariation.value);
                              }}
                              placeholder="Select Variation"
                              value={getSelectedVariation(product) || null}
                            />
                          </div>
                          {getSelectedVariation(product) ? (
                            <SelectButton
                              selected={selected}
                              addProduct={() => addProduct(product)}
                              removeProduct={() => removeProduct(product)}
                            />
                          ) : (
                            <div className="pt-9" />
                          )}
                        </div>
                      ) : (
                        <div className="pt-12">
                          <SelectButton
                            selected={selected}
                            addProduct={() => addProduct(product)}
                            removeProduct={() => removeProduct(product)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <textarea
                    disabled={!selected}
                    value={getNotesValue(product)}
                    onChange={(e) => {
                      updateProductNotes(product, e.target.value);
                    }}
                    className="my-6 border-[1px] rounded-xl border-cc-gray-500 w-full p-3 h-24 disabled:bg-gray-100"
                    placeholder="Add any patient notes here, dosing etc"
                  ></textarea>
                </div>
              );
            }
          })}
        </div>
      </div>
      {products.length > 0 && (
        <button
          disabled={!treatmentPlan.treatmentProducts?.length}
          className="text-sm sm:text-md ml-auto mt-auto px-4 bg-cc-blue-500 text-white my-4 py-2 rounded-full disabled:bg-gray-300"
          onClick={() => submit()}
        >
          Add to Treatment Plan
        </button>
      )}
    </div>
  );
};
