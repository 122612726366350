import axios from "axios";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router";

interface AuthInput {
  updateAuth: (isUserAuth: boolean) => void;
}

export const Auth: React.FC<AuthInput> = ({ updateAuth }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}auth/login`,
      {
        password,
      }
    );

    if (res.data) {
      updateAuth(true);
      navigate("/find-patient");
    } else {
      alert("Authentication Error - Incorrect Password");
    }
  };

  return (
    <div className="flex mr-auto ml-auto flex-row items-center justify-center h-screen">
      Password:
      <form onSubmit={(e) => onSubmit(e)}>
        <input
          type="password"
          maxLength={10}
          placeholder="Password"
          className="px-2 mx-4 rounded outline"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" hidden />
      </form>
    </div>
  );
};
