import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CannabisClinicLogoLight from "../assets/CannabisClinicLogoLight.svg";
import { TreatmentPlanContext } from "../contexts/TreatmentPlanContext";
import { TreatmentPlan } from "../interfaces";

const SplashScreen = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(3);
  const { updateTreatmentPlan } = useContext(TreatmentPlanContext);

  const treatmentPlanReset: TreatmentPlan = {
    treatmentProducts: [],
    dispensaryAccess: true,
  };

  useEffect(() => {
    setTimeout(function () {
      updateTreatmentPlan(treatmentPlanReset);
      window.location.href = "/";
      // navigate("/find-patient");
    }, 3000);
  }, []);

  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="flex flex-col w-full mb-8 flex-1 items-center text-3xl md:text-4xl font-[1000]">
      <div className="bg-cc-blue-500 w-full flex justify-center py-5">
        <img
          src={CannabisClinicLogoLight}
          alt="cannabisClinicLogo"
          className="h-16"
        />
      </div>
      <div className="mt-[20vh]">
        Your patients treatment plan has been sent, Thank you!
      </div>
      <div className="text-xl mt-10">
        You will be automatically redirected in {counter} seconds
      </div>
    </div>
  );
};

export default SplashScreen;
