import axios from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CannabisClinicLogoLight from "../assets/CannabisClinicLogoLight.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron-right.svg";
import { ReactComponent as DiscountIcon } from "../assets/discount icon.svg";
import InfoTabs from "../components/InfoTabs";
import ProductNotes from "../components/ProductNotes";
import { Tab, TreatmentPlan, TreatmentProduct } from "../interfaces";
import { resolveProductStrength } from "./CreateTreatmentPlan";

export const ViewTreatmentPlan = () => {
  const params = useParams();
  const [treatmentPlan, setTreatmentPlan] = useState<TreatmentPlan>();
  const [selectedTab, setSelectedTab] = useState<Tab>(InfoTabs[0]);
  const [productType, setProductType] = useState<string>();

  const updateQuantity = (productId: number, amount: number) => {
    if (!treatmentPlan) return;
    if (!treatmentPlan.treatmentProducts) return;

    const updatedProducts = treatmentPlan.treatmentProducts?.map((product) => {
      if (product.id === productId) {
        const quantity = Math.min(Math.max(product.quantity + amount, 1), 100);
        return { ...product, quantity };
      }

      return product;
    });

    setTreatmentPlan({ ...treatmentPlan, treatmentProducts: updatedProducts });
  };

  //TODO: https://tanstack.com/query/v4/?from=reactQueryV3&original=https://react-query-v3.tanstack.com/
  const getPlan = async () => {
    try {
      const treatmentPlanData = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}treatment-plan`,
        {
          params: {
            id: params.id,
          },
        }
      );

      setTreatmentPlan(treatmentPlanData.data);
      return treatmentPlan;
    } catch (err) {
      console.log(err);
    }
  };

  // Figure out whether the product(s) are CBD or THC, Lisa said THC products take precedent
  const setDominantProductType = () => {
    let typeOfProduct;
    if (treatmentPlan?.treatmentProducts) {
      for (const product of treatmentPlan?.treatmentProducts) {
        if (product.product?.categories) {
          for (const category of product.product?.categories) {
            if (category.slug.includes("cbd")) {
              if (!typeOfProduct) typeOfProduct = "cbd";
            } else if (category.slug.includes("thc")) {
              typeOfProduct = "thc";
            }
          }
        }
      }
    }
    setProductType(typeOfProduct);
  };

  const resolveTabContent = () => {
    if (selectedTab.content !== undefined) {
      // TODO: figure out how to get the thc status here
      return selectedTab.content(productType);
    }

    const renderSelectedTabContent = (
      selectedTabContent: string | { __html: string }
    ) => {
      const content =
        typeof selectedTabContent !== "string"
          ? selectedTabContent.__html
          : selectedTabContent;

      return (
        <div
          className="min-w-[0px] w-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    };

    const resolveHTML = (object: any) => {
      if (typeof object === "string") return object;
      else if (typeof object === "object" && object.__html)
        return object.__html;
      else return "";
    };

    if (selectedTab.slug === "notes") {
      let patientNotes =
        treatmentPlan?.patientNotes || "No patient notes provided by doctor";
      let content = "";

      if (productType === "cbd" && selectedTab.cbd) {
        content =
          "<span>" +
          patientNotes +
          "</span><br><br>" +
          resolveHTML(selectedTab.cbd);
      } else if (selectedTab.thc) {
        content =
          "<span>" +
          patientNotes +
          "</span><br><br>" +
          resolveHTML(selectedTab.thc);
      }

      return renderSelectedTabContent(content);
    }

    if (selectedTab.slug === "directions") {
      return <Directions />;
    }

    if (selectedTab.all) {
      return renderSelectedTabContent(selectedTab.all);
    }

    // console.log(selectedTab.thc)
    // console.log(selectedTab.cbd)

    if (selectedTab.thc || selectedTab) {
      if (productType === "cbd" && selectedTab.cbd != undefined) {
        return renderSelectedTabContent(selectedTab.cbd);
      } else if (selectedTab.thc != undefined) {
        return renderSelectedTabContent(selectedTab.thc);
      }
    }

    if (selectedTab.initial && selectedTab.followUp) {
      if (treatmentPlan?.appointmentType === "initial") {
        return renderSelectedTabContent(selectedTab.initial);
      } else {
        return renderSelectedTabContent(selectedTab.followUp);
      }
    }
  };

  const addToCart = () => {
    let url =
      "https://cannabisclinic.co.nz/wp-json/treatment-app/v1/add-to-cart?";
    treatmentPlan?.treatmentProducts?.forEach((treatmentProduct) => {
      url = url.concat(
        `products[]=${treatmentProduct.product?.id},${treatmentProduct.quantity}&`
      );
    });
    window.open(url);
  };

  useEffect(() => {
    getPlan();
  }, []);

  useEffect(() => {
    setDominantProductType();
  }, [treatmentPlan?.treatmentProducts]);

  return (
    <div className="flex flex-col flex-1 items-center text-3xl md:text-4xl font-[1000]">
      <div className="bg-cc-blue-500 w-full flex justify-center py-5">
        <img
          src={CannabisClinicLogoLight}
          alt="cannabisClinicLogo"
          className="h-16"
        />
      </div>

      <div className="flex flex-col items-center justify-center px-1 lg:px-20">
        <div className="mb-5 text-3xl mt-12">Medicines</div>
        <div className="flex flex-col lg:flex-row items-stretch lg:items-start">
          {/* Products container */}
          <div className="flex flex-col items-stretch lg:items-start lg:grid 2xl:grid-cols-2 grid-cols-1 gap-5 overflow-y-auto border border-cc-gray-200 rounded-xl text-xs md:gap-7 md:text-sm md:my-6 py-7 px-4 md:px-12 md:min-h-[14vw]">
            {treatmentPlan?.treatmentProducts?.map((treatmentProduct) => (
              <ProductCard
                key={treatmentProduct.id}
                treatmentProduct={treatmentProduct}
                updateQuantity={updateQuantity}
              />
            ))}
          </div>

          <div className="p-2 md:p-12 lg:p-0 w-full lg:w-1/3 md:m-4 m-1">
            <div className="flex lg:justify-start justify-center">
              <div className="flex mt-8 text-2xl md:text-3xl">
                Your Treatment Plan
              </div>
            </div>

            <div className="flex flex-col text-sm mt-5">
              <div className="text-sm md:text-base 2xl:flex gap-5 my-5">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "start", // or "space-around", "space-between", etc
                  }}
                >
                  {InfoTabs.map((tab) => {
                    return (
                      <div
                        onClick={() => setSelectedTab(tab)}
                        className={classNames(
                          "cursor-pointer px-5 py-2.5 rounded-full",
                          {
                            "bg-cc-blue-500 text-white": selectedTab === tab,
                            "bg-cc-gray-200": selectedTab !== tab,
                          }
                        )}
                        style={{
                          margin: "6px", // some margin to avoid sticking tabs to each other
                          boxSizing: "border-box",
                        }}
                        key={tab.slug}
                      >
                        {tab.label}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mx-auto w-full text-sm my-5 text-cc-gray-100 font-[100] overflow-y-auto lg:h-60 border rounded-xl px-5 py-5 text-left mb-0 flex-grow">
                {resolveTabContent()}
              </div>
            </div>
          </div>
        </div>

        <button
          className="flex items-center mt-5 px-4 py-3 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl"
          onClick={() => addToCart()}
        >
          Purchase Medicines
          <ChevronRight className="ml-2" />
        </button>

        <button
          className="flex items-center mt-6 px-4 py-3 bg-[#5197DE] text-white text-xs sm:text-sm rounded-3xl"
          onClick={() =>
            window.open("https://cannabisclinic.co.nz/nz-online-dispensary/")
          }
        >
          View other products in our Dispensary
          <ChevronRight className="ml-2" />
        </button>

        <div className="text-lg pt-3 pb-5 text-center">
          <div className="text-sm mt-1">Access Code: cbdoil</div>
        </div>
      </div>
    </div>
  );
};


const calculatePrice = (product: TreatmentProduct) => {
  // Exit function if product is not defined or doesn't have an id
  if (!product?.product?.id) return 0

  // Getting unit price and quantity from the product
  const price = product.product?.metaData.unit_price
  const quantity = product.quantity

  return price * quantity
}

// Function to calculate the final price of a product
const calculateFinalPrice = (product: TreatmentProduct) => {
  // Exit function if product is not defined or doesn't have an id
  if (!product?.product?.id) return 0

  // Getting unit price and quantity from the product
  const price = product.product?.metaData.unit_price
  const quantity = product.quantity
  const discounts = product.product?.metaData.discounts

  // Function to calculate total price without discount
  const normalPrice = price * quantity

  // fixme: Exit function if treatmentPlan is not defined or doesn't have the necessary properties for discount calculation
  // if (!treatmentPlan) return normalPrice;
  if (!discounts?.length) return normalPrice

  // Check if there is a discount that is triggered by the quantity of product purchased
  const discountToApply = discounts.find((discount) => discount.discount_quantity == quantity)
  // If a discount is found, calculate the final price with the discount applied
  if (discountToApply) {
    return (
      Number(discountToApply.discounted_price) || (price * quantity * (100 - discountToApply.discount_percentage)) / 100
    )
  }

  {
    // If no discount is found based on quantity, sort discounts by amountToTrigger in descending order and get the first one
    const discountToApply = discounts.sort(
      (discountA, discountB) => discountB.discount_quantity - discountA.discount_quantity
    )[0]

    // If the discount amountToTrigger is greater than quantity, return total price without discount
    if (discountToApply.discount_quantity > quantity) return normalPrice

    // Otherwise, apply the discount to the amountToTrigger quantity of product and add the price of the remaining products without discount
    return (
      (Number(discountToApply.discounted_price) ||
        discountToApply.discount_quantity * price * ((100 - discountToApply.discount_percentage) / 100)) +
      price * (quantity - discountToApply.discount_quantity)
    )
  }
}


const Directions = () => {
  return (
    <div>
      <div className="text-left mt-2">
        <div className="font-bold text-gray-500">CBD Oil: </div>Place oil
        under the tongue and hold for 60-90 seconds before swallowing.
        Ingesting with food that contains healthy fats such as avocado or nuts
        may help absorption. <br />
        <br />
        <div className="font-bold text-gray-500">CBD Topicals: </div>
        Apply a thin layer to the affected area 1-2 times a day. Start with a
        small amount and massage onto the area of concern. Based on what your
        doctor recommends, you can use alongside other CBD products, just be
        mindful of your dosage stacking up during the day. Not to be used on
        broken skin. Strictly not to be consumed or ingested. Avoid contact
        with eyes.
        <br />
        <br />
        <div className="font-bold text-gray-500">CBD Edibles: </div>
        Ingesting food that contains healthy fats such as avocado or nuts may
        help absorption.
        <br />
        <br />
        <div className="font-bold text-gray-500">THC Flower (Tea): </div>
        Bring 500ml (0.5 litres) of water to the boil in a covered vessel.
        Granulate product (using a grinder is easiest) into fine pieces. Add
        amount of product prescribed by your doctor to the vessel. Remove the
        covered vessel from the heat and strain the tea. Store the leftover
        tea in a thermos if you are to drink more of it on the same day. After
        preparation - as prescribed above add a sachet of coffee creamer
        powder to the warm tea. This prevents the active ingredients in the
        tea from sticking to the inside fof the teapot or mug after cooling,
        which could reduce the efficacy of the tea
        <br />
        <br />
        <div className="font-bold text-gray-500">
          THC Flower (Vaporisation):{" "}
        </div>{" "}
        Using an approved medicinal cannabis vaporiser with flower: <br /> 1.
        Grind the flower and fill the prescribed amount in the Dosing Capsule.
        <br /> 2. Insert the Dosing Capsule in the vaporiser.
        <br /> 3. Turn on the vaporiser and set target temperature with”+” and
        “-“ buttons.
        <br /> 4. When the required temperature is reached, inhale slowly and
        evenly for several seconds. Inhale only half of the air you can
        normally manage. Hold your breath for a few seconds and exhale slowly.
        Consciously concentrate on your inhalation.
        <br /> 5. After inhalation, turn off the vaporiser and remove the
        dosing capsule. Dispose of the used flower in the compost or residual
        waste.
        <br /> 6. Read the vaporiser instruction manual for more information.
        If you are known with respiratory tract or lung conditions, vaporisers
        might not be appropriate for you. <br />
        <br />
        How much to take: The number of inhalations you need each day depends
        on you as an individual. Each person needs a different number of
        inhalations to give them the best relief from their symptoms, with the
        least unwanted effects. When you first start taking the flower, you
        need to keep track of the number of inhalations/puffs until you find
        the best number for you. Stop increasing your number of inhalations
        when you find the best number of inhalations for you. This may only
        take a few days, or it may take up to 2 weeks. <br />
        <br />
        Aim to use this number of inhalations each day:
        <br /> • Start with 1 inhalation, wait 15 minutes;
        <br /> • Notice the effect you get;
        <br /> • Increase by 1 inhalation every 30 minutes if needed, until
        symptom relief and as tolerated;
        <br /> • Maximum of FOUR breathes in one setting (you might need
        less).
        <br />
        <br /> When to take it: THC flower can be taken with or without food.
        Taking the product under similar conditions (e.g., before or after
        food/meals) each day may help achieve a more consistent effect. THC
        flower should be taken regularly at the same time each day. If you
        miss your dose at the usual time, take it as soon as you remember,
        then go back to taking this product as you would normally. If you have
        missed a dose by more than 4 hours, skip the dose you missed and take
        your next dose when you are meant to.
        <br />
        <br />
      </div>
    </div>
  );
};

const ProductName = ({ name }: { name: string }) => (
  <div
    className="md:text-base font-semibold w-[150px] md:w-full"
    style={{ lineHeight: "1em", height: "2em", overflow: "hidden" }}
  >
    {name}
  </div>
);

const ProductStrength = ({ strength }: { strength: string }) => (
  <div
    className={classNames("font-bold text-orange-300", {
      "text-cc-high-strength": strength === "High",
      "text-cc-medium-strength": strength === "Medium",
      "text-cc-low-strength": strength === "Low",
    })}
  >
    {strength ? strength + " THC" : "\u200B"}
  </div>
);

const Discounts = ({ product }: { product: TreatmentProduct }) => {
  const discounts = product.product?.metaData.discounts;
  if (!discounts) return null;

  return (
    <div className="w-full h-[58px]">
      {discounts.map((discount) => (
        <div className="flex justify-start my-2" key={discount.discount_quantity}>
          <DiscountIcon />
          <span className="font-['Modelica-medium'] mx-2">
            Request {discount.discount_quantity} and
            {discount.discounted_price ?  ` pay only $${discount.discounted_price}` : ` get ${Number(discount.discount_percentage).toFixed(2)}% off`}
            </span>
        </div>
      ))}
    </div>
  );
};

const ProductCard = ({
  treatmentProduct,
  updateQuantity
}: {
  treatmentProduct: TreatmentProduct;
  updateQuantity: (productId: number, amount: number) => void
}) => {
  const productStrength = resolveProductStrength(
    treatmentProduct.product
  );
  return (
    <div
      className={classNames(
        "flex flex-row justify-start md:px-5 py-4 md:py-7 border border-cc-gray-500 rounded-xl min-h-[260px] md:min-h-[300px] p-2",
        {
          "border-cc-high-strength": productStrength === "High",
          "border-cc-medium-strength":
            productStrength === "Medium",
          "border-cc-low-strength": productStrength === "Low",
        }
      )}
    >
      <img
        src={treatmentProduct.product?.imageSrc}
        className="w-32 h-32 lg:w-40 lg:h-40 p-5"
        alt="ProductImage"
      />

      <div className="w-full">
        <ProductName
          name={treatmentProduct?.product?.name as string}
        />
        <div className="py-2">
          <ProductStrength
            strength={productStrength as string}
          />
          <ProductNotes
            notes={(() => {
              return (
                (treatmentProduct?.notes ?? "") +
                (treatmentProduct?.product?.metaData?.notes &&
                treatmentProduct?.notes
                  ? "\n\n"
                  : "") +
                (treatmentProduct?.product?.metaData?.notes ?? "")
              );
            })()}
          />
          {treatmentProduct.product?.attributes && (
            <div>
              Product Variation:
              {treatmentProduct?.product?.attributes[0].option}
            </div>
          )}
          <div className="flex justify-start">
            <div className="flex item-start justify-center items-center rounded-xl border-[1px] border-cc-gray-300 min-h-[40px]">
              <button
                className="p-2 px-2 pl-5 text-[22px] text-[#A2A2AD] font-light"
                onClick={(e) => {
                  updateQuantity(treatmentProduct.id, -1);
                }}
              >
                -
              </button>
              <span className="rounded-md text-center text-gray-700 w-10 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none">
                {treatmentProduct.quantity}
              </span>
              <button
                className="p-2 px-2 pr-5 text-[22px] text-[#A2A2AD] font-light"
                onClick={(e) => {
                  console.log("Appending");
                  updateQuantity(treatmentProduct.id, 1);
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className="my-4">
            <Discounts product={treatmentProduct} />
          </div>
          <hr />
          <div className="my-4">
            <PriceStikeout product={treatmentProduct} />
            <span className="font-black font-['Modelica-medium']">
              ${calculateFinalPrice(treatmentProduct).toFixed(2)}
            </span>{" "}
            <span>total</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const PriceStikeout = ({ product }: { product: TreatmentProduct }) => {
  const { discounts } = product.product?.metaData ?? {};

  const hasDiscounts = discounts?.length ?? 0 > 0;
  const priceIsDifferent: boolean =
    calculatePrice(product) -
    calculateFinalPrice(product) !=
    0;

  if (!hasDiscounts || !priceIsDifferent) {
    return null;
  }

  return (
    <>
      <span style={{ textDecoration: "line-through", color: "red" }}>
        ${calculatePrice(product).toFixed(2)}
      </span>
      <br />
    </>
  );
};
